// @mixin flexbox {
//     display: -webkit-box;
//     display: -moz-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
// }

// @include flexbox();

.home {
    position: relative;
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding-top: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.logo-cont {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        max-width: 350px;
        @media (max-width: 767px) {
            max-width: 200px;
        }
    }
}

.animation-cont {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-top: 50px;
}

.headline-cont {
    position: relative;
    width: 70%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
    @media (max-width: 767px) {
        width: 90%;
    }
    & h3 {
        margin: 5px 0px;
        font-size: 80px;
        font-weight: 400;
        @media (max-width: 1024px) {
            font-size: 60px;
        }
        @media (max-width: 768px) {
            font-size: 40px;
        }
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }
}