html, body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-width: 100vw;
  height: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
