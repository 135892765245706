@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

.App {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  padding: 0 8%;
  @media (max-width: 767px) {
    padding: 0 3%;
 }
}