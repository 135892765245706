.contact {
    position: relative;
    margin: 80px 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    & h4 {
        margin: 5px 0px;
        font-size: 24px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
}